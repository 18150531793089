<template>

  <div v-if="notificationData"><b-row><b-col md="12">

    <b-card>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Bildirimlerim
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1">
            <b-button
              v-if="notificationData.notification_count>0"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="markAsReadAll"
            >
              <b-badge
                variant="danger"
                style="top:1px !important;"
              >
                {{ notificationData.notification_count }} Okunmamış
              </b-badge>
              <span class="align-middle"> Tümünü Okundu Olarak İşaretle</span>
            </b-button>
            <b-badge
              v-else
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Tüm Bildirimle Okundu</span>
            </b-badge>
          </h5>

        </div>
        <!--/ badge -->
      </b-card-header>
      <!-- eslint-disable vue/no-v-html -->
      <b-card-body>
        <b-list-group>
          <b-list-group-item
            v-for="notification in notificationData.notifications.data"
            :key="notification.id"
            :to="$staticParams.buildNotificationLink(notification)"
            class="flex-column align-items-start"
            :variant="notification.is_read==false?'danger':''"
          >

            <div class="d-flex w-100 justify-content-between">
              <h5
                class="mb-1"
              >
                {{ notification.title }}
              </h5>
              <small class="text-secondary">{{ dateFormat(notification.created_at) }}</small>
            </div>
            <b-card-text class="mb-1">
              {{ notification.message }}
            </b-card-text>
            <small
              class="text-secondary"
            ><b-badge :variant="'light-'+$staticParams.getNotificationType(notification.warning_type).color">
              {{ $staticParams.getNotificationType(notification.warning_type).label }}
            </b-badge></small>
          </b-list-group-item>

        </b-list-group>
        <div>
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="handleChangePage"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

  </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BListGroup, BListGroupItem, BBadge,
  BCardHeader, BCardText,
  BRow,
  BCardBody,
  BCol,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BBadge,
    BRow,
    BCardBody,
    BCol,
    BPagination,
    BCardTitle,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCardSubTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      totalRows: 0,
      pageLength: 30,
      page: 1,
      notificationData: null,
    }
  },
  created() {
    this.fetchNotifications()
  },
  methods: {
    dateFormat(date) { return this.$moment(date).fromNow() },
    handleChangePage(page) {
      this.page = page
      this.fetchNotifications()
    },
    markAsReadAll() {
      store.dispatch('app/notificationMarkAsReaded', { notification_id: 0 })
        .then(() => {
          this.notificationData.notification_count = 0

          // eslint-disable-next-line no-param-reassign
          this.notificationData.notifications.data.forEach(val => { val.is_read = true })
        })
    },
    fetchNotifications() {
      store.dispatch('app/fetchNotifications', { page: this.page, all_notifications: true })
        .then(response => {
          this.notificationData = response.data.data
          const { meta } = response.data
          this.totalRows = meta.total
        })
    },
  },
}
</script>
